'use client'

// MUI Imports
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

// Third-party Imports
import classnames from 'classnames'
import {type ClientSafeProvider, type LiteralUnion, signIn} from "next-auth/react"
import type {BuiltInProviderType} from "next-auth/providers/index";

// Type Imports
import Link from '@mui/material/Link'

import type {Mode} from '@core/types'

// Hook Imports
import {useSettings} from '@core/hooks/useSettings'


const LoginV2 = ({providers}: {
  mode: Mode,
  providers:  Record<LiteralUnion<BuiltInProviderType, string>, ClientSafeProvider> | null
}) => {
  // Hooks
  const {settings} = useSettings()

  return (
    <div className='flex bs-full justify-center'>
      <div
        className={classnames(
          'flex bs-full items-center justify-center flex-1 min-bs-[100dvh] relative p-6 max-md:hidden',
          {
            'border-ie': settings.skin === 'bordered'
          }
        )}
      >
        <img alt="CPKC Livery" src="/CPKC-Livery-06-12-24-2100x14011.png" className='absolute bottom-[4%] z-[-1] is-full max-md:hidden'/>
      </div>
      <div className='flex justify-center items-center bs-full bg-backgroundPaper !min-is-full p-6 md:!min-is-[unset] md:p-12 md:is-[480px]'>
        <div
          className='flex flex-col gap-5 is-full sm:is-auto md:is-full sm:max-is-[400px] md:max-is-[unset] mbs-11 sm:mbs-14 md:mbs-0'>
          <div>
            <Typography variant='h5'>Canadian Pacific Kansas City Permit Application</Typography>
            <Typography className='mbs-1'>Please sign-in to your account and start the adventure</Typography>
          </div>
          {providers ?
            Object.values(providers).map((provider) => (
              <div key={provider.name} style={{marginBottom: 10}}>
                <Button onClick={() => signIn(provider.id)} fullWidth variant='contained' type='submit'>
                  Sign In / Sign Up
                </Button>
              </div>
            ))
            :
            <Typography color='error'>We were unable to load any login providers. Please contact an administrator.</Typography>
          }
          <div>
            <Typography className='mbs-1' fontWeight="bold">Note: To update or check the status of an existing application, sign into the <Link href="https://jllrpg.360works.com/fmi/webd/rpo_web_kcs.fmp12">legacy system</Link>.</Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginV2
